<template>
  <component :is="currentComponent" />
  <!-- <router-view></router-view> -->
</template>

<script>
const AirQuality = defineAsyncComponent(() =>
  import(/* webpackChunkName: "air-quality" */ "./components/AirQuality.vue")
);
const Finfacts = defineAsyncComponent(() =>
  import(/* webpackChunkName: "air-quality" */ "./components/Finfacts.vue")
);
const Lifestyle = defineAsyncComponent(() =>
  import(/* webpackChunkName: "air-quality" */ "./components/Lifestyle.vue")
);

import smoothscroll from "smoothscroll-polyfill";
// import scrollSnapPolyfill from "css-scroll-snap-polyfill";

import { onMounted, ref, defineAsyncComponent } from "@vue/runtime-core";

export default {
  name: "App",
  components: { AirQuality, Finfacts, Lifestyle },
  setup() {
    const currentComponent = ref();

    onMounted(() => {
      // kick off the polyfill!
      smoothscroll.polyfill();

      const host = window.location.host;
      const parts = host.split(".");
      // const domainLength = 3;

      const currentPathname = window.location.pathname;

      if (
        parts[0] === "airquality" ||
        parts[0] === "airquality-test" ||
        currentPathname === "/airquality"
      ) {
        currentComponent.value = <AirQuality />;
      } else if (
        parts[0] === "finfacts" ||
        parts[0] === "finfacts-test" ||
        currentPathname === "/finfacts"
      ) {
        currentComponent.value = <Finfacts />;
      } else if (
        parts[0] === "lifestyle" ||
        parts[0] === "lifestyle-test" ||
        currentPathname === "/lifestyle"
      ) {
        currentComponent.value = <Lifestyle />;
      } else {
        window.location.href = "https://www.telecine.ca/";
      }
    });

    return { currentComponent };
  },
};
</script>

<style>
:root {
  /* Colors: */
  --unnamed-color-002c3b: #002c3b;
  --unnamed-color-0096c7: #0096c7;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar,
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
*,
body,
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  src: local("EuropeBold"), url("./assets/fonts/Europe/Europe_Bold.ttf"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Europe/Europe_Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Europe/Europe_Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/Europe/Europe_Bold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/Europe/Europe_Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/Europe/Europe_Bold.otf")
      format("opentype");
  font-family: EuropeBold;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  src: local("Europe"), url("./assets/fonts/Europe/Europe.ttf"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Europe/Europe.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Europe/Europe.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/Europe/Europe.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/Europe/Europe.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/Europe/Europe.otf")
      format("opentype");
  font-family: Europe;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  src: local("SegoeBold"), url("./assets/fonts/Segoe/segoeuib.ttf"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Segoe/segoeuib.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Segoe/segoeuib.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/Segoe/segoeuib.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/Segoe/segoeuib.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/Segoe/segoeuib.otf")
      format("opentype");
  font-family: SegoeBold;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  src: local("RedhatBold"), url("./assets/fonts/Redhat/RedHatDisplay-Bold.ttf"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Redhat/RedHatDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Redhat/RedHatDisplay-Bold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Redhat/RedHatDisplay-Bold.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/Redhat/RedHatDisplay-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Redhat/RedHatDisplay-Bold.otf") format("opentype");
  font-family: RedhatBold;
  font-weight: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  border: none;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-type: mandatory;
  /* for older browsers */
  scroll-snap-points-y: repeat(100vh);
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: white;
  font-size: 16px;
}
</style>
