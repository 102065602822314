import smoothscroll from 'smoothscroll-polyfill';
// import router from "./router";

// kick off the polyfill!
smoothscroll.polyfill();

import {
    createApp
} from 'vue'
import App from './App.vue'

const app = createApp(App);

// app.use(router);

app.mount("#app");